function Flag(props) {
  const { value } = props;

  var flag = '';

  if (value.toLowerCase() === 'english') {
    flag = 'united-states';
  } else if (value.toLowerCase() === 'french') {
    flag = 'france';
  } else if (value.toLowerCase() === 'italian') {
    flag = 'italy';
  } else if (value.toLowerCase() === 'german') {
    flag = 'germany';
  } else if (value.toLowerCase() === 'vietnamese') {
    flag = 'vietnam';
  } else if (value.toLowerCase() === 'korean') {
    flag = 'south-korea';
  } else if (value.toLowerCase() === 'mandarin') {
    flag = 'china';
  } else if (value.toLowerCase() === 'spanish') {
    flag = 'spain';
  } else if (value.toLowerCase() === 'japanese') {
    flag = 'japan';
  } else {
    flag = '❓';
  }

  return (
    <img src={`./icons/${flag}.png`} alt={flag} style={{ maxWidth: '20px' }} />
  );
}

export default Flag;

