

function LanguageToFlag(props) {
  const { value } = props;

  var flag = '';

  const lowVal = value.toLowerCase();

  if (lowVal === 'english') {
    flag = 'us';
  } else if (lowVal === 'french') {
    flag = 'fr';
  } else if (lowVal === 'korean') {
    flag = 'kr';
  } else if (lowVal === 'mandarin') {
    flag = 'cn';
  } else if (lowVal === 'russian') {
    flag = 'rs';
  } else if (lowVal === 'greek') {
    flag = 'fi';
  } else if (lowVal === 'afrikaans') {
    flag = 'af';
    // LANG: Afrikaans (South Africa), af_ZA, 🇿🇦
  } else if (lowVal === 'amharic') {
    flag = 'am';
    // LANG: Amharic (Ethiopia), am_ET, 🇪🇹
  } else if (lowVal === 'arabic') {
    flag = 'ae';
    // LANG: Arabic (United Arab Emirates), ar_AE, 🇦🇪
  } else if (lowVal === 'armenian') {
    flag = 'am';
    // LANG: Armenian (Armenia), hy_AM, 🇦🇲
  } else if (lowVal === 'azerbaijani') {
    flag = 'az';
    // LANG: Azerbaijani (Azerbaijan), az_AZ, 🇦🇿
  } else if (lowVal === 'basque') {
    flag = 'eu';
    // LANG: Basque (Spain), eu_ES, 🇪🇸
  } else if (lowVal === 'bengali') {
    flag = 'bd';
    // LANG: Bengali (Bangladesh), bn-BD, 🇧🇩
  } else if (lowVal === 'bulgarian') {
    flag = 'bg';
    // LANG: Bulgarian (Bulgaria), bg-BG, 🇧🇬
  } else if (lowVal === 'burmese') {
    flag = 'my';
    // LANG: Burmese (Myanmar (Burma)), my_MM, 🇲🇲
  } else if (lowVal === 'cantonese') {
    flag = 'hk';
    // LANG: Cantonese (Traditional Han,Hong Kong), yue-HK, 🇭🇰
  } else if (lowVal === 'catalan') {
    flag = 'ct';
    // LANG: Catalan (Spain), ca_ES, ໋🏴
  } else if (lowVal === 'croatian') {
    flag = 'hr';
    // LANG: Croatian (Croatia), hr_HR, 🇭🇷
  } else if (lowVal === 'czech') {
    flag = 'cz';
    // LANG: Czech (Czechia), cs-CZ, 🇨🇿
  } else if (lowVal === 'danish') {
    flag = 'dk';
    // LANG: Danish (Denmark), da-DK, 🇩🇰
  } else if (lowVal === 'dutch') {
    flag = 'nl';
    // LANG: Dutch (Netherlands), nl-NL, 🇳🇱
  } else if (lowVal === 'estonian') {
    flag = 'ee';
    // LANG: Estonian (Estonia), et-EE, 🇪🇪
  } else if (lowVal === 'filipino') {
    flag = 'ph';
    // LANG: Filipino (Philippines), fil-PH, 🇵🇭
  } else if (lowVal === 'finnish') {
    flag = 'fi';
    // LANG: Finnish (Finland), fi-FI, 🇫🇮
  } else if (lowVal === 'french') {
    flag = 'ca';
    // LANG: French (Canada), fr-CA, 🇨🇦
  } else if (lowVal === 'galician') {
    flag = 'es';
    // LANG: Galician (Spain), gl_ES, 🇪🇸
  } else if (lowVal === 'georgian') {
    flag = 'ge';
    // LANG: Georgian (Georgia), ka_GE, 🇬🇪
  } else if (lowVal === 'german') {
    flag = 'de';
    // LANG: German (Germany), de-DE, 🇩🇪
  } else if (lowVal === 'greek') {
    flag = 'gr';
    // LANG: Greek (Greece), el-GR, 🇬🇷
  } else if (lowVal === 'hebrew') {
    flag = 'il';
    // LANG: Hebrew (Israel), iw_IL, 🇮🇱
  } else if (lowVal === 'hindi') {
    flag = 'in';
    // LANG: Hindi (India), hi-IN, 🇮🇳
  } else if (lowVal === 'hungarian') {
    flag = 'hu';
    // LANG: Hungarian (Hungary), hu-HU, 🇮🇳
  } else if (lowVal === 'icelandic') {
    flag = 'is';
    // LANG: Icelandic (Iceland), is_IS, 🇮🇸
  } else if (lowVal === 'indonesian') {
    flag = 'id';
    // LANG: Indonesian (Indonesia), in_ID, 🇮🇩
  } else if (lowVal === 'italian') {
    flag = 'it';
    // LANG: Italian (Italy), it-IT, 🇮🇹
  } else if (lowVal === 'japanese') {
    flag = 'jp';
    // LANG: Japanese (Japan), ja-JP, 🇯🇵
  } else if (lowVal === 'korean') {
    flag = 'kr';
    // LANG: Korean (South Korea), ko-KR, 🇰🇷
  } else if (lowVal === 'norwegian') {
    flag = 'no';
    // LANG: Norwegian Bokmål (Norway), nb-NO, 🇳🇴
  } else if (lowVal === 'polish') {
    flag = 'pl';
    // LANG: Polish (Poland), pl-PL, 🇵🇱
  } else if (lowVal === 'polish') {
    flag = 'pl';
    // LANG: Polish (Poland), pl_PL, 🇵🇱
  } else if (lowVal === 'portuguese') {
    flag = 'pt';
    // LANG: Portuguese (Portugal), pt-PT, 🇵🇹
  } else if (lowVal === 'russian') {
    flag = 'ru';
    // LANG: Russian (Russia), ru-RU, 🇷🇺
  } else if (lowVal === 'serbian') {
    flag = 'sr';
    // LANG: Serbian (Serbia), sr_RS, 🇷🇸
  } else if (lowVal === 'sinhala') {
    flag = 'lk';
    // LANG: Sinhala (Sri Lanka), si-LK, 🇱🇰
  } else if (lowVal === 'spanish') {
    flag = 'es';
    // LANG: Spanish (Spain), es_ES, 🇪🇸
  } else if (lowVal === 'swahili') {
    flag = 'tz';
    // LANG: Swahili (Tanzania), sw_TZ, 🇨🇭
    // } else if (lowVal === 'sw
    // flag = '';ahilisw_';//) {
    // // LANG: Swahili, sw_, 🇸🇪
  } else if (lowVal === 'swedish') {
    flag = 'se';
    // LANG: Swedish (Sweden), sv-SE, 🇸🇪
  } else if (lowVal === 'tamil') {
    flag = 'in';
    // LANG: Tamil (India), ta-IN, 🇱🇰
  } else if (lowVal === 'thai') {
    flag = 'th';
    // LANG: Thai (Thailand), th-TH, 🇹🇭
  } else if (lowVal === 'turkish') {
    flag = 'tr';
    // LANG: Turkish (Turkey), tr-TR, 🇹🇷
  } else if (lowVal === 'ukrainian') {
    flag = 'ua';
    // LANG: Ukrainian (Ukraine), uk-UA, 🇺🇦
  } else if (lowVal === 'urdu') {
    flag = 'pk';
    // LANG: Urdu (Pakistan), ur-PK, 🇵🇰
  } else if (lowVal === 'uzbek') {
    flag = 'uz';
    //LANG: Uzbek (Uzbekistan), uz_UZ, 🇺🇿
  } else if (lowVal === 'vietnamese') {
    flag = 'vn';
    // LANG: Vietnamese (Vietnam), vi-VN, 🇻🇳
  } else if (lowVal === 'zulu') {
    flag = 'za';
    // LANG: Zulu (South Africa), zu_ZA, 🇿🇲
  } else if (lowVal === 'mandarin') {
    flag = 'cn';
    // LANG: cmn (Simplified Han,China), cmn_CN, 🇨🇳
  } else if (lowVal === 'cantonese') {
    flag = 'hk';
    // LANG: cmn (Simplified Han,Hong Kong), cmn_HK, 🇭🇰
  } else if (lowVal === 'hebrew') {
    flag = 'il';
    // LANG: , he-IL, 🇮🇱
  } else {
    flag = 'ca';
  }

  return (
    <span className={`fi fi-${flag}`}></span>
  );
}

export default LanguageToFlag;


//LANG: Afrikaans (South Africa), af_ZA, 🇿🇦
//LANG: Amharic (Ethiopia), am_ET, 🇪🇹
//LANG: Arabic (Algeria), ar_DZ, 🇩🇿
//LANG: Arabic (Bahrain), ar_BH, 🇧🇭
//LANG: Arabic (Egypt), ar_EG, 🇪🇬
//LANG: Arabic (Israel), ar_IL, 🇮🇱
//LANG: Arabic (Jordan), ar_JO, 🇯🇴
//LANG: Arabic (Kuwait), ar_KW, 🇰🇼
//LANG: Arabic (Lebanon), ar_LB, 🇱🇧
//LANG: Arabic (Morocco), ar_MA, 🇲🇦
//LANG: Arabic (Oman), ar_OM, 🇴🇲
//LANG: Arabic (Palestine), ar_PS, 🇵🇸
//LANG: Arabic (Qatar), ar_QA, 🇶🇦
//LANG: Arabic (Saudi Arabia), ar_SA, 🇸🇦
//LANG: Arabic (Tunisia), ar_TN, 🇹🇳
//LANG: Arabic (United Arab Emirates), ar_AE, 🇦🇪
//LANG: Armenian (Armenia), hy_AM, 🇦🇲
//LANG: Azerbaijani (Azerbaijan), az_AZ, 🇦🇿
//LANG: Basque (Spain), eu_ES, 🇪🇸
//LANG: Bengali (Bangladesh), bn-BD, 🇧🇩
//LANG: Bengali (Bangladesh), bn_BD, 🇧🇩
//LANG: Bengali (India), bn-IN, 🇮🇳
//LANG: Bengali (India), bn_IN, 🇮🇳
//LANG: Bulgarian (Bulgaria), bg-BG, 🇧🇬
//LANG: Bulgarian (Bulgaria), bg_BG, 🇧🇬
//LANG: Burmese (Myanmar (Burma)), my_MM, 🇲🇲
//LANG: Cantonese (Traditional Han,Hong Kong), yue-HK, 🇭🇰
//LANG: Cantonese (Traditional Han,Hong Kong), yue_HK, 🇭🇰
//LANG: Catalan (Spain), ca_ES, ໋🏴
//LANG: Croatian (Croatia), hr_HR, 🇭🇷
//LANG: Czech (Czechia), cs-CZ, 🇨🇿
//LANG: Czech (Czechia), cs_CZ, 🇨🇿
//LANG: Danish (Denmark), da-DK, 🇩🇰
//LANG: Danish (Denmark), da_DK, 🇩🇰
//LANG: Dutch (Netherlands), nl-NL, 🇳🇱
//LANG: Dutch (Netherlands), nl_NL, 🇳🇱
//LANG: English (Australia), en-AU, 🇦🇺
//LANG: English (Australia), en_AU, 🇦🇺
//LANG: English (Canada), en_CA, 🇨🇦
//LANG: English (Ghana), en_GH, 🇬🇭
//LANG: English (India), en-IN, 🇮🇳
//LANG: English (India), en_IN, 🇮🇳
//LANG: English (Indonesia), en_ID, 🇮🇳
//LANG: English (Ireland), en_IE, 🇮🇪
//LANG: English (Kenya), en_KE, 🇰🇪
//LANG: English (New Zealand), en_NZ, 🇳🇿
//LANG: English (Nigeria), en-NG, 🇿🇦
//LANG: English (Nigeria), en_NG, 🇿🇦
//LANG: English (Philippines), en_PH, 🇵🇭
//LANG: English (Singapore), en_SG, 🇸🇬
//LANG: English (South Africa), en_ZA, 🇿🇲
//LANG: English (Tanzania), en_TZ, 🇹🇿
//LANG: English (Thailand), en_TH, 🇹🇭
//LANG: English (United Kingdom), en-GB, 🇬🇧
//LANG: English (United Kingdom), en_GB, 🇬🇧
//LANG: English (United States), en-US, 🇺🇸
//LANG: English (United States), en_US, 🇺🇸
//LANG: English (World), en_001, 🌎
//LANG: Estonian (Estonia), et-EE, 🇪🇪
//LANG: Estonian (Estonia), et_EE, 🇪🇪
//LANG: Filipino (Philippines), fil-PH, 🇵🇭
//LANG: Filipino (Philippines), fil_PH, 🇵🇭
//LANG: Finnish (Finland), fi-FI, 🇫🇮
//LANG: Finnish (Finland), fi_FI, 🇫🇮
//LANG: French (Canada), fr-CA, 🇨🇦
//LANG: French (Canada), fr_CA, 🇨🇦
//LANG: French (France), fr-FR, 🇫🇷
//LANG: French (France), fr_FR, 🇫🇷
//LANG: Galician (Spain), gl_ES, 🇪🇸
//LANG: Georgian (Georgia), ka_GE, 🇬🇪
//LANG: German (Austria), de_AT, 🇩🇪
//LANG: German (Germany), de-DE, 🇩🇪
//LANG: German (Germany), de_DE, 🇩🇪
//LANG: Greek (Greece), el-GR, 🇬🇷
//LANG: Greek (Greece), el_GR, 🇬🇷
//LANG: Gujarati (India), gu-IN, 🇮🇳
//LANG: Gujarati (India), gu_IN, 🇮🇳
//LANG: Hebrew (Israel), iw_IL, 🇮🇱
//LANG: Hindi (India), hi-IN, 🇮🇳
//LANG: Hindi (India), hi_IN, 🇮🇳
//LANG: Hungarian (Hungary), hu-HU, 🇮🇳
//LANG: Hungarian (Hungary), hu_HU, 🇮🇳
//LANG: Icelandic (Iceland), is_IS, 🇮🇸
//LANG: Indonesian (Indonesia), in_ID, 🇮🇩
//LANG: Italian (Italy), it-IT, 🇮🇹
//LANG: Italian (Italy), it_IT, 🇮🇹
//LANG: Japanese (Japan), ja-JP, 🇯🇵
//LANG: Japanese (Japan), ja_JP, 🇯🇵
//LANG: Javanese (Indonesia), jv-ID, 🇮🇩
//LANG: Javanese (Indonesia), jv_ID, 🇮🇩
//LANG: Kannada (India), kn-IN, 🇮🇳
//LANG: Kannada (India), kn_IN, 🇮🇳
//LANG: Khmer (Cambodia), km-KH, 🇰🇭
//LANG: Khmer (Cambodia), km_KH, 🇰🇭
//LANG: Korean (South Korea), ko-KR, 🇰🇷
//LANG: Korean (South Korea), ko_KR, 🇰🇷
//LANG: Lao (Laos), lo_LA, 🇱🇦
//LANG: Latvian (Latvia), lv-LV, 🇱🇻
//LANG: Latvian (Latvia), lv_LV, 🇱🇻
//LANG: Lithuanian (Lithuania), lt_LT, 🇱🇹
//LANG: Malay (Malaysia), ms-MY, 🇲🇾
//LANG: Malay (Malaysia), ms_MY, 🇲🇾
//LANG: Malayalam (India), ml-IN, 🇲🇾
//LANG: Malayalam (India), ml_IN, 🇲🇾
//LANG: Marathi (India), mr-IN, 🇮🇳
//LANG: Marathi (India), mr_IN, 🇮🇳
//LANG: Nepali (Nepal), ne-NP, 🇳🇵
//LANG: Nepali (Nepal), ne_NP, 🇳🇵
//LANG: Norwegian Bokmål (Norway), nb-NO, 🇳🇴
//LANG: Norwegian Bokmål (Norway), nb_NO, 🇳🇴
//LANG: Persian (Iran), fa_IR, 🇮🇷
//LANG: Polish (Poland), pl-PL, 🇵🇱
//LANG: Polish (Poland), pl_PL, 🇵🇱
//LANG: Portuguese (Brazil), pt-BR, 🇧🇷
//LANG: Portuguese (Brazil), pt_BR, 🇧🇷
//LANG: Portuguese (Portugal), pt-PT, 🇵🇹
//LANG: Portuguese (Portugal), pt_PT, 🇵🇹
//LANG: Romanian (Romania), ro-RO, 🇷🇴
//LANG: Romanian (Romania), ro_RO, 🇷🇴
//LANG: Russian (Russia), ru-RU, 🇷🇺
//LANG: Russian (Russia), ru_RU, 🇷🇺
//LANG: Serbian (Serbia), sr_RS, 🇷🇸
//LANG: Sinhala (Sri Lanka), si-LK, 🇱🇰
//LANG: Sinhala (Sri Lanka), si_LK, 🇱🇰
//LANG: Slovak (Slovakia), sk-SK, 🇸🇰
//LANG: Slovak (Slovakia), sk_SK, 🇸🇰
//LANG: Slovenian (Slovenia), sl_SI, 🇸🇮
//LANG: Spanish (Argentina), es_AR, 🇦🇷
//LANG: Spanish (Bolivia), es_BO, 🇧🇴
//LANG: Spanish (Chile), es_CL, 🇨🇱
//LANG: Spanish (Colombia), es_CO, 🇨🇴
//LANG: Spanish (Costa Rica), es_CR, 🇨🇷
//LANG: Spanish (Dominican Republic), es_DO, 🇩🇴
//LANG: Spanish (Ecuador), es_EC, 🇪🇨
//LANG: Spanish (El Salvador), es_SV, 🇸🇻
//LANG: Spanish (Guatemala), es_GT, 🇬🇹
//LANG: Spanish (Honduras), es_HN, 🇭🇳
//LANG: Spanish (Mexico), es_MX, 🇲🇽
//LANG: Spanish (Nicaragua), es_NI, 🇳🇮
//LANG: Spanish (Panama), es_PA, 🇵🇦
//LANG: Spanish (Paraguay), es_PY, 🇵🇾
//LANG: Spanish (Peru), es_PE, 🇵🇪
//LANG: Spanish (Puerto Rico), es_PR, 🇵🇷
//LANG: Spanish (Spain), es-ES, 🇪🇸
//LANG: Spanish (Spain), es_ES, 🇪🇸
//LANG: Spanish (United States), es-US, 🇺🇸
//LANG: Spanish (United States), es_US, 🇺🇸
//LANG: Spanish (Uruguay), es_UY, 🇺🇾
//LANG: Spanish (Venezuela), es_VE, 🇻🇪
//LANG: Sundanese (Indonesia), su-ID, 🇸🇩
//LANG: Sundanese (Indonesia), su_ID, 🇸🇩
//LANG: Swahili (Tanzania), sw_TZ, 🇨🇭
//LANG: Swahili, sw_, 🇸🇪
//LANG: Swedish (Sweden), sv-SE, 🇸🇪
//LANG: Swedish (Sweden), sv_SE, 🇸🇪
//LANG: Tamil (India), ta-IN, 🇱🇰
//LANG: Tamil (India), ta_IN, 🇱🇰
//LANG: Tamil (Malaysia), ta_MY, 🇲🇾
//LANG: Tamil (Singapore), ta_SG, 🇸🇬
//LANG: Tamil (Sri Lanka), ta_LK, 🇱🇰
//LANG: Telugu (India), te-IN, 🇮🇳
//LANG: Telugu (India), te_IN, 🇮🇳
//LANG: Thai (Thailand), th-TH, 🇹🇭
//LANG: Thai (Thailand), th_TH, 🇹🇭
//LANG: Turkish (Turkey), tr-TR, 🇹🇷
//LANG: Turkish (Turkey), tr_TR, 🇹🇷
//LANG: Ukrainian (Ukraine), uk-UA, 🇺🇦
//LANG: Ukrainian (Ukraine), uk_UA, 🇺🇦
//LANG: Urdu (India), ur_IN, 🇮🇳
//LANG: Urdu (Pakistan), ur-PK, 🇵🇰
//LANG: Urdu (Pakistan), ur_PK, 🇵🇰
//LANG: Uzbek (Uzbekistan), uz_UZ, 🇺🇿
//LANG: Vietnamese (Vietnam), vi-VN, 🇻🇳
//LANG: Vietnamese (Vietnam), vi_VN, 🇻🇳
//LANG: Zulu (South Africa), zu_ZA, 🇿🇲
//LANG: , ar, 🇦🇪
//LANG: , bs-BA, 🏴
//LANG: , ca, ໋🏴
//LANG: cmn (Simplified Han,China), cmn_CN, 🇨🇳
//LANG: cmn (Simplified Han,Hong Kong), cmn_HK, 🇭🇰
//LANG: cmn (Traditional Han,Taiwan), cmn_TW, 🇹🇼
//LANG: , cy, 🏴󠁧󠁢󠁷󠁬󠁳󠁿
//LANG: , he-IL, 🇮🇱
//LANG: , hr, 🇭🇷
//LANG: , id-ID, 🇮🇩
//LANG: , nl-BE, 🇧🇪
//LANG: , pa-IN, 🇮🇳
//LANG: , sq-AL, 🏴
//LANG: , sr, 🇷🇸
//LANG: , sw-KE, 🇸🇪
//LANG: , zh-CN, 🇨🇳
//LANG: , zh-TW, 🇹🇼