import { useState, useEffect } from 'react';


import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { Modal } from 'react-bootstrap';


import Flag from './Flag';

import VocabBuilder from './VocabBuilder';

const sleep = m => new Promise(r => setTimeout(r, m))

const wizToCSV = (wizData) => {
  const wizDataCopy = [...wizData];
  const i = wizDataCopy.findIndex((row) => (row[0] === '' && row[1] === ''));
  if (i !== -1) wizDataCopy.splice(i, 1);

  return wizDataCopy.join('\n');
}

function LibraryQuickAddForm(props) {
  const {
    cat,
    postNew,
    success,
    name,
    setName,
    wizVocab,
    setWizVocab,
    csvVocab,
    setCsvVocab,
    note,
    setNote,
    invalidRow,
    setInvalidRow,
    closeBuildNew,
  } = props;


  const [enableWiz, setEnableWiz] = useState(true);

  useEffect(() => {
    setInvalidRow('');

    var lines = csvVocab.split('\n');
    lines.forEach((line, i) => {
      var fields = line.split(',');

      if (line !== '' && fields.length !== 2) {
        setInvalidRow(`Line ${i + 1} invalid: ${line}`);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wizVocab, csvVocab, name, note]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log('~~~~~~~~~~');
    // console.log('~~~~~~~~~~');
    // console.log('~~~~~~~~~~');
    // console.log('(to-do) compile data here...');

    // check name
    // console.log('name: ', name);
    // check vocab
    var userVocab = '';
    if (enableWiz) {
      userVocab = wizToCSV(wizVocab);
    } else {
      userVocab = csvVocab;
    }
    // console.log('wizVocab:', wizVocab);
    // console.log('csvVocab:', csvVocab);
    // check note
    // console.log('note:', note);

    postNew({
      name: name,
      vocab: userVocab,
      note: note,
    });
  }

  const handleToggleWiz = async (val) => {
    setEnableWiz(!val.target.checked);
  }

  return (
    <>
      {/* <Modal.Header closeButton> */}
      {/* </Modal.Header> */}
      <Modal.Body className="bg-dark text-white">
        <form
          onSubmit={(e) => handleSubmit(e)}
          className="card bg-light pb-4 bg-dark text-white">
          <Modal.Title className="my-2 ms-3 me-2 d-flex justify-content-between">
            <div>
              Add New <span style={{ color: '#0d6efd' }}>{cat.category}</span> Shareable:
            </div>
            <IconButton color="primary" aria-label="close" onClick={async () => {
              await sleep(300);
              closeBuildNew()
            }}>
              <CloseIcon />
            </IconButton>

          </Modal.Title>
          {/* <div className="d-flex px-3 py-2 justify-content-end">
            New
            <span className="mx-1 text-secondary">
              {cat.category}
            </span>
            Shareable:

          </div> */}
          <div className="mx-2">
            <TextField
              label={'Name of Shareable'}
              variant="outlined"
              fullWidth
              key={'name_of_shareable'}
              id={'name_of_shareable'}
              value={name}
              onChange={(e) => {
                const unsanitized = e.target.value;
                const sanitized = unsanitized.replace(/[^a-zA-Z0-9_-]/g, '');

                setName(sanitized);
              }}
            />
          </div>



          <div className="d-flex justify-content-between mt-3 mx-2">
            <div style={{ background: '#333', borderRadius: '0.375rem' }} className="px-3 py-1">
              <Flag value={cat.q_lang} />
              <span className="d-inline-box ps-2">{cat.q_lang}</span>
            </div>
            <div style={{ background: '#333', borderRadius: '0.375rem' }} className="px-3 py-1">
              <Flag value={cat.a_lang} />
              <span className="d-inline-box ps-2">{cat.a_lang}</span>
            </div>
          </div>



          <VocabBuilder
            wizVocab={wizVocab}
            setWizVocab={setWizVocab}
            csvVocab={csvVocab}
            setCsvVocab={setCsvVocab}
            enableWiz={enableWiz}
            invalidRow={invalidRow}
            handleToggleWiz={handleToggleWiz}
          />

          {/* note language */}
          <div className="px-2">
            <TextField
              label={'Note'}
              variant="outlined"
              fullWidth
              key={'note_of_shareable'}
              id={'note_of_shareable'}
              value={note}
              onChange={(e) => {
                setNote(e.target.value);
              }}
            />
          </div>

          {success &&
            <div className="mx-2">
              <div className="d-flex justify-content-center mt-3 py-2 bg-success text-light rounded w-100">
                Study set saved!
              </div></div>
          }

          <div className="d-inline-block mt-0 align-center">
            <div className="text-danger mb-3">{invalidRow}</div>
            <button className="btn btn-primary mb-0" type="submit">Submit</button>
          </div>

        </form>

      </Modal.Body></>);
}

export default LibraryQuickAddForm;