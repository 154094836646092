import { useRef } from "react";
import { Overlay, Tooltip, Card } from 'react-bootstrap';

export default function VocabHelp(props) {
  const { showHelp, setShowHelp } = props;
  const target = useRef(null);

  return (
    <>
      <div className="help" ref={target} onClick={() => setShowHelp(!showHelp)} style={{
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
      }}>
        <span>
          ?
        </span>
      </div>
      <Overlay target={target.current} show={showHelp} placement="left">
        {(props) => (
          <Tooltip id="overlay-collection-helper" {...props}>
            <span className="d-flex justify-content-between">
              <small>Rules:</small>
              <small
                style={{ cursor: 'pointer' }}
                onClick={() => setShowHelp(false)}>
                X
              </small>
            </span>
            <Card className="p-3 my-2">
              <small>- Question are separated from answers by commas (,)</small>
              <small>- Alternate answers are separated by slashes (/)</small>
              <small>- Each question should be on a new line</small>
            </Card>


            <small>For example...</small>
            <Card className="p-3 mt-2">
              <small>we are,nous somme</small>
              <small>you are,vous etes/tu es</small>
              <small>hello/hi,bonjour/salut</small>
            </Card>
          </Tooltip>
        )}
      </Overlay>
    </>
  );
}