import { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

import ChooseAliasModal from './ChooseAliasModal';

export default function ChooseAlias(props) {
  const { auth, user, setUserAlias } = props;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return auth.currentUser && (
    <>
      <Button className="mx-3" onClick={() => handleShow()}>
        Choose Alias
      </Button>
      <Modal show={show} onHide={handleClose}>
        <ChooseAliasModal handleClose={handleClose} user={user} setUserAlias={setUserAlias} />
      </Modal>
    </>
  );
}