export default function Android() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      style={{ width: '50px', height: '50px' }}
    >
      <path
        d="M14.97535,3.01886l.95982-1.73159a.19342.19342,0,0,0-.33833-.18756l-.97045,1.75078a6.54141,6.54141,0,0,0-5.25275,0L8.40316,1.09971a.19342.19342,0,0,0-.33833.18756l.95985,1.7316A5.54614,5.54614,0,0,0,5.93152,7.89522h12.137A5.54615,5.54615,0,0,0,14.97535,3.01886ZM9.19911,5.67446a.5068.5068,0,1,1,.5068-.5068A.50737.50737,0,0,1,9.19911,5.67446Zm5.60178,0a.5068.5068,0,1,1,.5068-.5068A.50737.50737,0,0,1,14.80089,5.67446Zm-8.86946,11.497a1.46713,1.46713,0,0,0,1.46713,1.46713h.9736v3.00095a1.36046,1.36046,0,1,0,2.72091,0V18.63859h1.81386v3.00095a1.36046,1.36046,0,1,0,2.72091,0V18.63859h.97364a1.46713,1.46713,0,0,0,1.46713-1.46713V8.37532H5.93143ZM4.06415,8.14191A1.362,1.362,0,0,0,2.7037,9.50237v5.66846a1.36046,1.36046,0,1,0,2.72091,0V9.50237A1.362,1.362,0,0,0,4.06415,8.14191Zm15.8717,0a1.362,1.362,0,0,0-1.36046,1.36046v5.66846a1.36046,1.36046,0,1,0,2.72091,0V9.50237A1.362,1.362,0,0,0,19.93585,8.14191Z"
      />
    </svg>
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   height="48"
    //   width="48">
    //   <path
    //     d="M2 36.05q.4-5.3 3.25-9.825Q8.1 21.7 12.8 19.05L9.05 12.6q-.15-.45-.025-.9t.525-.7q.45-.25.975-.1.525.15.775.6l3.7 6.35Q19.3 16 24 16t9 1.85l3.75-6.35q.25-.45.775-.6.525-.15.975.1.4.25.575.725.175.475-.075.875l-3.8 6.45q4.7 2.65 7.55 7.175Q45.6 30.75 46 36.05Zm12-5.5q1 0 1.75-.75t.75-1.75q0-1-.75-1.75T14 25.55q-1 0-1.75.75t-.75 1.75q0 1 .75 1.75t1.75.75Zm20 0q1 0 1.75-.75t.75-1.75q0-1-.75-1.75T34 25.55q-1 0-1.75.75t-.75 1.75q0 1 .75 1.75t1.75.75Z"
    //   />
    // </svg>
  );
}