import { useState } from 'react';
import { Modal, Table } from 'react-bootstrap';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';



// accordion
import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
// import Typography from '@mui/material/Typography';
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function CategoryShortcut(props) {
  const [expanded, setExpanded] = React.useState(true);

  const toggleExpanded = () => {

    if (expanded === true) {
      setExpanded(false);
      return;
    }

    setExpanded(true);
  };

  const { name, cat, isLast, getMoreShortcutData, shortcutHasMoreResults } = props;

  const [shownShareableName, setShownShareableName] = useState(false);
  const [shownShareableVocab, setShownShareableVocab] = useState('');
  const [showShareable, setShowShareable] = useState(false);

  return (
    <Accordion key={`child-${cat.docId}`}
      expanded={expanded}
      onChange={() => toggleExpanded()}
      style={{ borderBottom: isLast ? "1px solid rgba(0, 0, 0, 0.12)" : null, }}
      sx={{ background: '#333' }}
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Typography>{name}</Typography>
      </AccordionSummary>

      <AccordionDetails className="" style={{ borderTop: 'none' }}>
        {
          cat.map(
            (shareable) => {

              const date = new Date(shareable.last_modified.seconds * 1000);
              const formattedDate = `${date.getFullYear().toString().substr(-2)}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;


              return <Typography key={`shareable-${shareable.docId}`}>
                <Button onClick={() => {
                  setShownShareableName(shareable.name);
                  setShownShareableVocab(shareable.vocab);
                  setShowShareable(true);
                }} variant="outlined" className="mb-1 d-flex justify-content-between w-100"
                  style={{ textTransform: 'none' }}
                >
                  <span>Name: {shareable.name}</span>
                  <div style={{ width: '24px' }}></div>
                  <span>{formattedDate}</span>
                </Button>
              </Typography>
            })
        }

        {/* more button should go here... */}
        {shortcutHasMoreResults ? <Button onClick={() => {
          getMoreShortcutData();
        }} variant="outlined" className="mb-1 d-flex justify-content-center w-100"
          style={{ textTransform: 'none', textAlign: 'center' }}
        >
          More...
        </Button> : <></>}


        <Modal show={showShareable} onHide={() => setShowShareable(false)}>
          <Modal.Header closeButton className="bg-dark text-white">
            <Modal.Title>{name}: {shownShareableName}</Modal.Title>
          </Modal.Header>

          <Modal.Body className="bg-dark text-white">
            <Table >
              <tbody className="bg-dark text-white">
                {shownShareableVocab.split('\n').map((line, i) => {
                  const terms = line.split(',');
                  return (
                    <tr key={'id' + i}>
                      <td>
                        {terms[0]},
                      </td>
                      <td>
                        {terms[1]}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </Modal.Body>
        </Modal>
      </AccordionDetails>
    </Accordion>
  );
}

export default CategoryShortcut;