import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { getFirestore } from '@firebase/firestore';


const firebaseConfig = {
  apiKey: "AIzaSyBC6rfNVry4kvi1-d3En192e557De-MHi0",
  authDomain: "web-echo-prof-v0.firebaseapp.com",
  projectId: "web-echo-prof-v0",
  storageBucket: "web-echo-prof-v0.appspot.com",
  messagingSenderId: "232579061838",
  appId: "1:232579061838:web:bef18c814bdc5f52ab0b1f",
  measurementId: "G-TNJW91418T"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);


export const userDetails = {
  name: null,
  email: null,
  profilePic: null,
};

const provider = new GoogleAuthProvider();

export const signInWithGoogle = () => {
  signInWithPopup(auth, provider).then((result) => {

    // console.log('signed in...');

  }).catch((error) => {
    // console.log(error);
  });
}
