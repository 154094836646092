import { Table } from 'react-bootstrap';

export default function ComparisonTable() {
  return (
    <Table hover style={{ color: '#ddd' }}>
      <thead>
        <tr>
          <th></th>
          <th>Duolingo</th>
          <th>Anki</th>
          <th>Echo Prof</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Premade study sets</td>
          <td>✅</td>
          <td>❌</td>
          <td>✅</td>
        </tr>
        <tr>
          <td>Community study sets</td>
          <td>❌</td>
          <td>✅</td>
          <td>✅</td>
        </tr>
        <tr>
          <td>Spaced repetition</td>
          <td>❌</td>
          <td>✅</td>
          <td>✅</td>
        </tr>
        {/* <tr>
          <td>User friendly</td>
          <td>✅</td>
          <td>❌</td>
          <td>✅</td>
        </tr> */}
        <tr>
          <td>Can create study material</td>
          <td>❌</td>
          <td>✅</td>
          <td>✅</td>
        </tr>
        <tr>
          <td>Has no ads</td>
          <td>❌</td>
          <td>✅</td>
          <td>✅</td>
        </tr>
        <tr>
          <td>Has no in-app purchases</td>
          <td>❌</td>
          <td>✅</td>
          <td>✅</td>
        </tr>
        <tr>
          <td>Speech input</td>
          <td>❌</td>
          <td>❌</td>
          <td>✅</td>
        </tr>
        <tr>
          <td>Speech output</td>
          <td>❌</td>
          <td>❌</td>
          <td>✅</td>
        </tr>
        <tr>
          <td><b>Can study with eyes closed (mostly)!!</b></td>
          <td>❌</td>
          <td>❌</td>
          <td>✅</td>
        </tr>
      </tbody>
      {/* Duolingo	Anki	Echo Prof */}
      {/* Spaced repitition	No	Yes	Yes */}
      {/* User friendly	Yes	No	Yes */}
      {/* Premade study sets	Yes	No	Yes */}
      {/* Speech recognition 	No	No	Yes */}
      {/* Speech synthesis	No	No	Yes */}
      {/* Community study sets	No	Yes	Yes */}
      {/* Can study with eyes closed (mostly)	No	No	Yes */}
      {/* Can create study material	No	Yes	Yes */}
      {/* Has ads	Yes	No	No */}
      {/* Has in-app purchases 	Yes	No	No */}
    </Table>
  );
}