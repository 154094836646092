import { useState } from 'react';

import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import VocabHelp from './VocabHelp';

function VocabBuilder(props) {
  const {
    wizVocab,
    setWizVocab,
    csvVocab,
    setCsvVocab,
    enableWiz,
    handleToggleWiz,
  } = props;

  const [showHelp, setShowHelp] = useState(false);

  return (<><div className="card bg-dark text-white border-none px-0 mt-0 pt-0 pb-1">
    {enableWiz ? <>
      {
        wizVocab.map((line, i) => {

          const findEmptyRows = () => {
            let totalEmpties = 0;
            let finalEmptyIndex = -1;
            wizVocab.forEach((row, i) => {
              if (row[0] === '' && row[1] === '') {
                totalEmpties++;
                finalEmptyIndex = i;
              }
            });
            return [totalEmpties, finalEmptyIndex];
          }

          return (
            <span key={'wiz-vocab' + i} className="mt-2 mx-2 d-flex position-relative">
              <span
                className="position-absolute"
                style={{
                  left: -16,
                  top: 6,
                  backgroundColor: '#333',
                  color: 'white',
                  width: 16,
                  height: 16,
                  fontSize: 10,
                }}>{i + 1}</span>
              {line.map((segment, j) => {
                // const registerName = (j === 0 ? 'q' : 'a' + (i + 1));
                return <TextField label={j === 0 ? 'Question' : 'Answer'} variant="outlined" fullWidth
                  className={j === 0 ? 'me-2' : 'me-0'}
                  key={'wiz-vocab' + i + '-' + j}
                  id={'wiz-vocab' + i + '-' + j}
                  value={segment}
                  onChange={(e) => {
                    const newVocab = [...wizVocab];
                    newVocab[i][j] = e.target.value;
                    // if the last row has value, add another row
                    const [empties, finalEmptyIndex] = findEmptyRows();
                    if ((empties < 1 && newVocab[newVocab.length - 1][0] !== '') || newVocab[newVocab.length - 1][1] !== '') {
                      newVocab.push(['', '']);
                    }
                    // if there is more than one empty row, then delete the one nearest the bottom
                    if (empties > 1) {
                      newVocab.splice(finalEmptyIndex, 1);
                    }

                    // remove all commas (because we're converting to CSV on submit)
                    newVocab.forEach((row, i) => {
                      newVocab[i][0] = newVocab[i][0].replaceAll(',', ' - ');
                      newVocab[i][1] = newVocab[i][1].replaceAll(',', ' - ');
                    });

                    setWizVocab(newVocab);
                  }}
                />
              })}
            </span>
          );
        })}

    </> : <>
      <div className="mt-3 mx-2">
        <TextField
          id="outlined-multiline-flexible"
          // label="Multiline"
          placeholder={"Enter your study set in the format: \nquesion1,answer1\nquestion2,answer2"}
          multiline
          rows={5}
          className="bg-dark text-white"
          value={csvVocab}
          onChange={(e) => setCsvVocab(e.target.value)}
          fullWidth
        />
      </div>
      {/* <div className="p-1 pt-2 d-flex justify-content-end mx-2">
            <VocabHelp showHelp={showHelp} setShowHelp={setShowHelp} />
          </div> */}
    </>
    }

  </div>

    <div className="d-flex justify-content-end mx-3 mb-1">

      <FormControlLabel
        value="start"
        control={<Switch color="primary" onChange={(val) => handleToggleWiz(val)} />}
        label="CSV Mode (Advanced)"
        labelPlacement="start"
      />
      <div className="mt-2 ms-3">
        {!enableWiz && <VocabHelp showHelp={showHelp} setShowHelp={setShowHelp} />}
      </div>
    </div>
  </>);
}

export default VocabBuilder;