import Android from './icons/Android';
import Apple from './icons/Apple';

import { useState, useEffect } from 'react';

function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

export default function DownloadButtons() {
  const [device, setDevice] = useState('');

  useEffect(() => {
    setDevice(getMobileOperatingSystem());
  }, []); // need the array here so that the accordions don't auto-close (don't understand why)

  return (<div className="pb-0 mb-0 text-center">
    <div className="pb-2">Study with Echo Prof <br />on iOS or Android:</div>
    <div className="d-flex justify-content-center flex-row align-items-center">

      <a style={{ textDecoration: 'solid', border: '5px solid #894e12', padding: device === 'iOS' ? '12px' : '3px' }}
        className="btn  btn-secondary bg-lighter mx-3"
        href="https://apps.apple.com/ca/app/keep-it-simple-silly/id1580578692">
        <Apple />
      </a>

      <a style={{ textDecoration: 'solid', border: '5px solid #894e12', padding: device === 'Android' ? '12px' : '3px' }}
        className="btn btn-secondary bg-lighter"
        href="https://play.google.com/store/apps/details?id=com.kiss_srs.abc&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
        <Android />
      </a>
    </div>
  </div >);
}