import { useState } from 'react';
import { db } from './firebase-config';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { Table, Modal, Form, Button } from 'react-bootstrap';

import { auth } from './firebase-config';
import Flag from './Flag';
import DownloadButtons from './DownloadButtons';

export default function StudySetModal(props) {
  const { studySet, setStudySet, userDetails } = props;
  const [vocab, setVocab] = useState(studySet.vocab);
  const [isLoading, setIsLoading] = useState(false);

  let time = { seconds: 0, nanoseconds: 0 };
  var now = new Date().toISOString();

  if (studySet.createdAt != null) {
    time = {
      seconds: studySet.createdAt.seconds,
      nanoseconds: studySet.createdAt.nanoseconds,
    }
  }

  const updateCollection = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (studySet === '') {
      alert('Study set has no content!');
      return;
    }

    const { uid } = auth.currentUser;

    var editedShareable = {
      tag: studySet.tag,
      name: studySet.name,
      question: studySet.question,
      answer: studySet.answer,
      vocab: vocab,
      note: studySet.note,
      createdAt: serverTimestamp(),
      userAlias: userDetails.displayName, //userAliasData[0].alias,
      uid
    }

    // update the doc on Firebase
    await setDoc(doc(db, "collections", studySet.name), editedShareable);
    setStudySet({ ...editedShareable, createdAt: now });
    // studySet.vocab = vocab;
    setIsLoading(false);
  }

  // const createReversedStudyset = async (e) => {
  //   e.preventDefault();
  //   console.log('hi');

  //   var reversedVocab = '';

  //   var lines = vocab.split('\n');
  //   for (var x = 0; x < lines.length; x++) {
  //     var qa = lines[x].split(',');
  //     var aq = qa[1] + ',' + qa[0];
  //     reversedVocab += aq + '\n';

  //   }

  //   console.log(reversedVocab);

  //   const { uid } = auth.currentUser;

  //   var details = {
  //     tag: studySet.tag,
  //     name: studySet.name,
  //     question: studySet.answer,
  //     answer: studySet.question,
  //     vocab: vocab, // to alter...
  //     note: studySet.note,
  //     createdAt: serverTimestamp(),
  //     userAlias: userDetails.displayName,
  //     uid
  //   }
  // }

  const firebaseTime = new Date(time.seconds * 1000 + time.nanoseconds / 1000000);
  var date = firebaseTime.toString() === 'Invalid Date' ? now.split('T')[0] : firebaseTime.toISOString().split('T')[0];

  return (
    <>
      <Modal.Header closeButton className="bg-dark text-white">
        <Modal.Title>View Study Set</Modal.Title>
      </Modal.Header>

      <Modal.Body className="bg-dark text-white">
        <div className="d-flex justify-content-between small font-weight-lighter">
          <span>User: {studySet.userAlias}</span>
          <span>Last Modified: {date}</span>
        </div>
        <div className="d-flex justify-content-start">
          <div className="pe-3">

            <span className="pe-1">Question Language:</span>
            {(studySet.question !== undefined && studySet.answer !== undefined) &&
              <Flag value={studySet.question} />}
            <span className="ps-1">{studySet.question}</span>

          </div>
          <div>

            <span className="pe-1">Answer Language:</span>
            {(studySet.question !== undefined && studySet.answer !== undefined) &&
              <Flag value={studySet.answer} />}
            <span className="ps-1">{studySet.answer}</span>

          </div>
        </div>

        <hr />

        <p>{studySet.name}</p>
        {userDetails && userDetails.uid === studySet.uid ?
          <Form onSubmit={updateCollection}>
            <Form.Control
              placeholder="Enter your study set in the format: 'quesion,answer'" as="textarea" rows={5}
              value={vocab} ///>
              onChange={(e) => setVocab(e.target.value)} />

            {isLoading ? <>Loading...</> : null}

            {vocab !== studySet.vocab ? <div className="d-flex justify-content-end my-3">
              <Button className="text-right" type="submit">Save Updated Content</Button>
            </div> : null}

          </Form>

          :
          <Table hover>
            <tbody>
              {studySet.vocab.split('\n').map((line, i) => {
                const terms = line.split(',');
                return (
                  <tr key={'id' + i}>
                    <td>
                      {terms[0]},
                    </td>
                    <td>
                      {terms[1]}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        }
        {studySet.note && <div className="pb-3 px-2 small">
          Note: {studySet.note}
        </div>}
        {/* {userDetails && userDetails.uid === studySet.uid && <div className="m-0 pe-1 d-flex justify-content-end">
          <Button onClick={createReversedStudyset}>Create study set with reversed languages</Button>
        </div>} */}
        <div className="mt-4 text-center">
          <span className="pe-1">
            Shareable link:
          </span>
          <a href={`https://www.echoprof.com/${studySet.name}`} className="d-inline-block pb-3 pt-0">
            {`https://www.echoprof.com/${studySet.name}`}
          </a>
          <DownloadButtons />
        </div>
      </Modal.Body>
    </>
  );

}