import { useState, useEffect } from 'react';
import { db, auth } from './firebase-config';
import { doc, getDoc, serverTimestamp, setDoc, collection, getDocs, query, orderBy, limit, startAfter } from 'firebase/firestore';
import { Modal, Table } from 'react-bootstrap';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';
import LibraryQuickAddForm from './LibraryQuickAddForm';
// import Chevron from './icons/Chevron';
// import ChevronUp from './icons/ChevronUp';



// accordion
import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
// import Typography from '@mui/material/Typography';
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const sleep = m => new Promise(r => setTimeout(r, m))

function LibraryCategory(props) {
  ///////
  const [expanded, setExpanded] = React.useState(false);

  const toggleExpanded = () => {
    // setExpanded(!expanded);

    if (expanded === true) {
      setExpanded(false);
      return;
    }

    setExpanded(true);

    // console.log('setting to expanded...')

    // console.log(`shareables.length: ${shareables.length}`);
    if (shareables.length === 0) {
      // console.log('shareables.length: ', shareables.length)
      // console.log('calling for shareables...')
      getLibraryShareables(cat.docId);
    }
  };
  ///////


  const { cat, userAlias, isLast, userDetails } = props;

  const [shareables, setShareables] = useState([]);
  // const [show, setShow] = useState(false);
  const [buildNew, setBuildNew] = useState(false);
  const [success, setSuccess] = useState('');
  const [invalidRow, setInvalidRow] = useState('');

  const [shownCreatedBy, setShownCreatedBy] = useState('');
  const [shownShareableInvalidRow, setShownShareableInvalidRow] = useState(false);
  const [shownShareableName, setShownShareableName] = useState(false);
  const [shownShareableUid, setShownShareableUid] = useState(false);
  const [shownShareableVocab, setShownShareableVocab] = useState('');
  const [shownShareableNote, setShownShareableNote] = useState('');
  const [showShareable, setShowShareable] = useState(false);
  const [modifiedVocab, setModifiedVocab] = useState('');
  const [enableUpdate, setEnableUpdate] = useState(false);
  const [hasMoreResults, setHasMoreResults] = useState(true);

  // const handleCloseAdd = async () => {
  //   setBuildNew(false);
  // }


  useEffect(() => {
    setShownShareableInvalidRow('');
    // console.log('checking rows again..');

    var lines = modifiedVocab.split('\n');
    lines.forEach((line, i) => {
      var fields = line.split(',');

      if (line !== '' && fields.length !== 2) {
        setShownShareableInvalidRow(`Line ${i + 1} invalid: ${line}`);
      }
    });
  }, [modifiedVocab, shownShareableNote]);

  // const expandCategory = async (catId) => {
  //   if (show === true) {
  //     setShow(false);
  //     return;
  //   }

  //   setShow(true);

  //   if (shareables.length === 0) {
  //     console.log('shareables.length: ', shareables.length)
  //     getLibraryShareables(catId);
  //   }
  // }

  const getLibraryShareables = async (catId) => {
    // console.log('called get getLibraryShareables...')

    // get shareables from specific category:
    const categoryRef = collection(db, `library/${catId}/shareables`);
    const newCategoryQuery = query(categoryRef, orderBy('last_modified', 'desc'), limit(10));
    const queryNewCatSnapshot = await getDocs(newCategoryQuery);
    var categoryData = [];
    queryNewCatSnapshot.forEach((doc) => {
      categoryData = [...categoryData, { docId: doc.id, ...doc.data() }];
    });

    const newCatData = categoryData;

    // console.log(newCatData);

    // console.log(newCatData);
    setShareables(newCatData);
  }

  const getMoreLibraryShareables = async (catId) => {
    const libraryRef = collection(db, `library/${catId}/shareables`);
    const lastDoc = shareables[shareables.length - 1]; // Get the last document from the previous query
    const newCategoryQuery = query(libraryRef, orderBy('last_modified', 'desc'), startAfter(lastDoc.last_modified), limit(10)); // Use startAfter to get the next set of results
    const queryNewCatSnapshot = await getDocs(newCategoryQuery);
    var moreShareables = [];
    queryNewCatSnapshot.forEach((doc) => {
      moreShareables = [...moreShareables, { docId: doc.id, ...doc.data() }
      ];
    });

    if (queryNewCatSnapshot.size < 10) {
      setHasMoreResults(false); // Set the hasMoreResults state to false if there are no more results
    } else {
      setHasMoreResults(true);
    }

    setShareables([...shareables, ...moreShareables]); // Add the new results to the previous results
  }

  const [name, setName] = useState('');
  const [wizVocab, setWizVocab] = useState([['', '']]);
  const [csvVocab, setCsvVocab] = useState('');
  const [note, setNote] = useState('');

  const postNew = async (data) => {
    const { uid } = auth.currentUser;
    const name = userAlias;

    const newPost = {
      created_by: name,
      created_by_id: uid,
      last_modified: serverTimestamp(),
      name: data.name,
      vocab: data.vocab,
      note: data.note,
    };

    // console.log(newPost);

    // create shareable if it doesn't exist
    const postRef = doc(
      db,
      `library/${cat.docId}/shareables`,
      data.name.replace(' ', '-'),
    );

    const postSnapshot = await getDoc(postRef);

    if (postSnapshot.exists()) {
      // A document with the same name already exists
      // Handle the error, or return early, or do nothing
      setInvalidRow('Shareable with same name already exists!');
      return;
    } else {
      // Create a new document since it doesn't exist yet
      await setDoc(postRef, newPost);
    }

    // create the doc on Firebase
    // await setDoc(doc(db, `library/${cat.docId}/shareables`, data.name.replace(' ', '-')), newPost);
    setSuccess(true);
    // add to the parent collection...

    setName('');
    setWizVocab([['', '']]);
    setCsvVocab('');
    setNote('');
    await sleep(500);
    setBuildNew(false);
    setSuccess(false);

    // now update the shareables with the newPost
    const localTimestamp = {
      seconds: Math.floor(Date.now() / 1000),
      nanoseconds: 0
    };
    const newShareables = [{
      created_by: newPost.created_by,
      created_by_id: newPost.created_by_id,
      last_modified: localTimestamp,
      name: newPost.name,
      vocab: newPost.vocab,
      note: newPost.note,
      docId: newPost.name,
    }, ...shareables];
    // does this break everything? 🤔
    setShareables(newShareables);
  }

  const saveChanges = async () => {
    const { uid } = auth.currentUser;
    const name = userAlias;

    const updatedPost = {
      created_by: name,
      created_by_id: uid,
      last_modified: serverTimestamp(),
      name: shownShareableName,
      vocab: modifiedVocab,
      note: shownShareableNote,
    }

    // console.log(updatedPost);

    const postRef = doc(
      db,
      `library/${cat.docId}/shareables`,
      shownShareableName
    );
    await setDoc(postRef, updatedPost);

    setSuccess(true);
    await sleep(500);
    setShowShareable(false);
    setSuccess(false);
  }

  return (
    <Accordion key={`child-${cat.docId}`}
      expanded={expanded}
      onChange={() => toggleExpanded()}
      style={{ borderBottom: isLast ? "1px solid rgba(0, 0, 0, 0.12)" : null, }}
      sx={{ background: '#333' }}
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Typography>{cat.category}</Typography>
      </AccordionSummary>
      {/* {!buildNew && <button className="btn btn-primary mt-3" onClick={() => setBuildNew(true)}>Build new {cat.category} shareable</button>} */}
      {!buildNew && userDetails && userAlias !== '' && userAlias.length > 0 && <div className="w-100 d-flex justify-content-end mt-2 px-2">
        <Button onClick={() => setBuildNew(true)} variant="contained" className="mb-2 mt-2 me-2 d-flex justify-content-between">
          <AddIcon />
          <span style={{ paddingTop: '2px', textTransform: 'none' }}>
            <span style={{ textTransform: 'uppercase' }}>Add New </span>
            {cat.category}
            <span style={{ textTransform: 'uppercase', paddingLeft: '3px' }}>Shareable</span>
          </span>
        </Button>
      </div>}


      <AccordionDetails className="" style={{ borderTop: 'none' }}>
        {/* <Modal show={buildNew} onHide={handleCloseAdd}> */}
        {(buildNew && userDetails && userAlias !== '' && userAlias.length > 0) && <div className="mt-2">
          <LibraryQuickAddForm
            cat={cat}
            postNew={postNew}
            success={success}
            name={name}
            setName={setName}
            wizVocab={wizVocab}
            setWizVocab={setWizVocab}
            csvVocab={csvVocab}
            setCsvVocab={setCsvVocab}
            note={note}
            setNote={setNote}
            invalidRow={invalidRow}
            setInvalidRow={setInvalidRow}
            closeBuildNew={() => setBuildNew(false)}
          />
        </div>}
        {
          shareables.map(

            (shareable) => {

              const date = new Date(shareable.last_modified.seconds * 1000);
              const formattedDate = `${date.getFullYear().toString().substr(-2)}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;

              // console.log(shareable);

              return <Typography key={`shareable-${shareable.docId}`}>
                <Button onClick={() => {
                  setShownShareableName(shareable.name);
                  setShownShareableUid(shareable.created_by_id);
                  setShownCreatedBy(shareable.created_by);
                  setShownShareableVocab(shareable.vocab);
                  setModifiedVocab(shareable.vocab);
                  setShownShareableNote(shareable.note);
                  setShowShareable(true);
                }} variant="outlined" className="mb-1 d-flex justify-content-between w-100"
                  style={{ textTransform: 'none' }}
                >
                  <span>Name: {shareable.name}</span>
                  <div style={{ width: '24px' }}></div>
                  <span>{formattedDate}</span>
                </Button>
              </Typography>
            })
        }

        {/* more button should go here... */}
        {hasMoreResults ? <Button onClick={() => {
          getMoreLibraryShareables(cat.docId);
        }} variant="outlined" className="mb-1 d-flex justify-content-center w-100"
          style={{ textTransform: 'none', textAlign: 'center' }}
        >
          More...
        </Button> : <></>}

        <Modal show={showShareable} onHide={() => setShowShareable(false)}>
          <Modal.Header closeButton className="bg-dark text-white">
            <Modal.Title>Created by: {shownCreatedBy}<br />{cat.category}: {shownShareableName}</Modal.Title>
          </Modal.Header>

          <Modal.Body className="bg-dark text-white">
            {auth.currentUser && shownShareableUid === auth.currentUser.uid ? <><TextField
              placeholder={"Enter your study set in the format: \nquesion1,answer1\nquestion2,answer2"}
              multiline
              rows={20}
              value={modifiedVocab}
              onChange={(e) => {
                if (e.target.value !== modifiedVocab) {
                  // console.log('enable saving now...');
                  setEnableUpdate(true);
                }
                setModifiedVocab(e.target.value);
              }}
              fullWidth
            />
              <div className="px-0 mt-2">
                <TextField
                  label={'Note'}
                  variant="outlined"
                  fullWidth
                  key={'note_of_shown_shareable'}
                  id={'note_of_shown_shareable'}
                  value={shownShareableNote}
                  onChange={(e) => {
                    if (e.target.value !== shownShareableNote) {
                      setEnableUpdate(true);
                    }
                    setShownShareableNote(e.target.value);
                  }}
                />
                <div className="text-danger mb-3">{shownShareableInvalidRow}</div>
              </div>
              {enableUpdate && <div className="d-flex justify-content-end">
                <Button onClick={() => {
                  // console.log('update the collection here...');
                  !shownShareableInvalidRow && saveChanges();
                }}
                  variant={shownShareableInvalidRow ? "disabled" : "contained"}
                  className="mb-1 mt-3 d-flex justify-content-center">
                  Save Changes
                </Button>
              </div>}
              {success &&
                <div className="mx-2">
                  <div className="d-flex justify-content-center mt-3 py-2 bg-success text-light rounded w-100">
                    Study set updated!
                  </div>
                </div>
              }
            </> :
              <Table >
                <tbody className="bg-dark text-white">
                  {shownShareableVocab.split('\n').map((line, i) => {
                    const terms = line.split(',');
                    return (
                      <tr key={'id' + i}>
                        <td>
                          {terms[0]},
                        </td>
                        <td>
                          {terms[1]}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            }
            {/* If user is not creator */}

            {/* If user is creator */}

          </Modal.Body>
        </Modal>


        {/* </Modal> */}

      </AccordionDetails>
    </Accordion>
  );
}

export default LibraryCategory;