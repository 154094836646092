import { useState, useEffect } from 'react';
import './App.css';
import { db } from './firebase-config';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { collection, doc, getDoc, getDocs, query, orderBy, where, limit, startAfter } from 'firebase/firestore';
import { signInWithGoogle, auth } from './firebase-config';
import { Modal, Container } from 'react-bootstrap';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import RepeatIcon from '@mui/icons-material/Repeat';
import StudySetShortcut from './StudySetShortcut';
import ChooseAlias from './ChooseAlias';
import Intro from './Intro';
import AboutModal from './AboutModal';
import LibraryCategory from './LibraryCategory';
import CategoryShortcut from './CategoryShortcut';
import LibraryGeneralAddForm from './LibraryGeneralAddForm';
import LangSelector from './LangSelector';
import LanguageToFlag from './LanguageToFlag';
import "/node_modules/flag-icons/css/flag-icons.min.css";
// import OldApp from "./xlegacy/OldApp";
// flag icons above were found here: https://www.npmjs.com/package/flag-icons

// nested data fetching?
// https://stackoverflow.com/questions/65778594/get-nested-data-from-firestore-database-in-react-js

let hasRun = false;
let blockPopup = false;
const pathName = window.location.pathname !== '/' ? window.location.pathname.slice(1) : '';

const usersRef = collection(db, "users");

function App() {
  const [libraryData, setLibraryData] = useState([]);
  const [shortcutData, setShortcutData] = useState([]);
  const [shortcutHasMoreResults, setShortcutHasMoreResults] = useState(true);
  const [showIntro, setShowIntro] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [showAddGeneral, setShowAddGeneral] = useState(false);

  const [showAbout, setShowAbout] = useState(false);
  const handleCloseAbout = () => setShowAbout(false);
  const handleShowAbout = () => setShowAbout(true);

  const tmpQLang = JSON.parse(localStorage.getItem('savedQLang'));
  const tmpALang = JSON.parse(localStorage.getItem('savedALang'));
  const [selectedQLang, setSelectedQLang] = useState(tmpQLang ? tmpQLang : 'English');
  const [selectedALang, setSelectedALang] = useState(tmpALang ? tmpALang : 'Japanese');


  useEffect(() => {
    localStorage.setItem('savedQLang', JSON.stringify(selectedQLang));
  }, [selectedQLang]);
  useEffect(() => {
    localStorage.setItem('savedALang', JSON.stringify(selectedALang));
  }, [selectedALang]);


  useEffect(() => {
    getLibraryCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedQLang, selectedALang]);

  const [userAlias, setUserAlias] = useState('');

  const logout = async () => {
    // block popup from showing on logout
    blockPopup = true;
    await signOut(auth);
  }

  const getLibraryCategories = async () => {
    // get categories from library
    const libraryRef = collection(db, "library");
    const newQ = query(libraryRef,
      where('q_lang', '==', selectedQLang),
      where('a_lang', '==', selectedALang),
      orderBy("last_modified", "desc")
    );
    const queryNewSnapshot = await getDocs(newQ);
    var _libraryData = [];
    queryNewSnapshot.forEach((doc) => {
      _libraryData = [
        ..._libraryData, { docId: doc.id, ...doc.data(), shareables: [] }
      ];
    })
    // console.log('~~~getLibraryCategories~~~');
    // console.log(libraryData);
    setLibraryData(_libraryData);
  }

  const getShortcutLibraryCategory = async (pathName) => {
    // set the languages
    const catRef = doc(db, `library/${pathName}`);
    const catSnapshot = await getDoc(catRef);
    const catData = catSnapshot.data();

    if (catData !== undefined) {
      setSelectedQLang(catData['q_lang']);
      setSelectedALang(catData['a_lang']);
    }

    // set the categories
    const libraryRef = collection(db, `library/${pathName}/shareables`);
    const newCategoryQuery = query(libraryRef, orderBy('last_modified', 'desc'), limit(20));
    const queryNewCatSnapshot = await getDocs(newCategoryQuery);
    var _shortcutData = [];
    queryNewCatSnapshot.forEach((doc) => {
      _shortcutData = [
        ..._shortcutData, { docId: doc.id, ...doc.data(), shareables: [] }
      ];
    });

    setShortcutData(_shortcutData);
  }

  const getMoreShortcutData = async () => {
    const libraryRef = collection(db, `library/${pathName}/shareables`);
    const lastDoc = shortcutData[shortcutData.length - 1]; // Get the last document from the previous query
    const newCategoryQuery = query(libraryRef, orderBy('last_modified', 'desc'), startAfter(lastDoc.last_modified), limit(20)); // Use startAfter to get the next set of results
    const queryNewCatSnapshot = await getDocs(newCategoryQuery);
    var _newShortcutData = [];
    queryNewCatSnapshot.forEach((doc) => {
      _newShortcutData = [..._newShortcutData, { docId: doc.id, ...doc.data(), shareables: [] }
      ];
    });

    if (queryNewCatSnapshot.size < 20) {
      setShortcutHasMoreResults(false); // Set the hasMoreResults state to false if there are no more results
    } else {
      setShortcutHasMoreResults(true);
    }

    setShortcutData([...shortcutData, ..._newShortcutData]); // Add the new results to the previous results
  }

  // ON STARTUP:
  useEffect(() => {
    // console.log('(to-do) when user adds collection, they should not need to refresh');

    const queryUserAlias = async (user) => {
      const q = query(usersRef, where("uid", "==", user.uid));
      const querySnapshot = await getDocs(q);
      const treatedData = [];
      querySnapshot.forEach((doc) => {
        treatedData.push({ expanded: false, docId: doc.id, ...doc.data() });
      });

      setUserAlias(treatedData[0].alias);
    }

    const monitorAuthState = async () => {
      onAuthStateChanged(auth, user => {
        if (user) {
          // console.log('user: ', user);
          setUserDetails(user);
          queryUserAlias(user);
          // updateCategoryIDs(user.uid);
        } else {
          // console.log('not logged in?...');
          if (!blockPopup) {
            setShowIntro(true);
          }
          setUserDetails(null);
        }
      });
    }


    if (hasRun === false) {
      hasRun = true;
      // getCategories();
      getLibraryCategories();
      monitorAuthState();
      if (pathName !== '') {
        getShortcutLibraryCategory(pathName);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // need the array here so that the accordions don't auto-close (don't understand why)

  return (
    <Container className="App my-3 px-3">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div>
          <img src="./logo192.png" style={{ width: "75px", borderRadius: "12px" }} alt="Echo Prof" />
          <span className="px-3" style={{
            fontSize: "12pt",
            // fontWeight: 200,
            letterSpacing: "0.5px",
            lineHeight: 1.2,
            textDecoration: "none",
            // color: "#ddd",
          }}>
            Echo Prof
          </span>
        </div>
        <StudySetShortcut userDetails={userDetails} />
        <span className="d-flex align-items-center">
          {userDetails && userAlias !== '' && userAlias.length > 0 ?
            <span className="inline-block mx-3">{userAlias}</span> :
            <ChooseAlias auth={auth} setUserAlias={setUserAlias} />}
          {userDetails ?
            <Button onClick={logout} variant="outlined" className="mb-1 d-flex justify-content-between">Sign Out</Button>
            :
            <Button onClick={signInWithGoogle} variant="contained" className="mb-1 d-flex justify-content-between">Sign in with Google</Button>
          }
        </span>
      </div>

      {/* display shortcutData */}
      {shortcutData.length > 0 ?
        <div className="pb-3"><CategoryShortcut
          name={pathName}
          key={`shortcut-${pathName}`}
          cat={shortcutData}
          userAlias={userAlias}
          isLast={true}
          userDetails={userDetails}
          getMoreShortcutData={getMoreShortcutData}
          shortcutHasMoreResults={shortcutHasMoreResults} /></div> : <></>}

      <Intro showIntro={showIntro} setShowIntro={setShowIntro} />

      <div className="card p-2" style={{ background: '#333' }}>
        <div className="pt-1 pb-2">Recent Languages:</div>
        <div className="d-flex justify-content-between">
          <Button onClick={() => {
            setSelectedQLang('English');

            setSelectedALang('French');
          }} variant="contained" className="mb-1 d-flex justify-content-between w-100 me-3">
            <LanguageToFlag value={'English'} />
            <RepeatIcon />
            <LanguageToFlag value={'French'} />
          </Button>
          <Button onClick={() => {
            setSelectedQLang('English');

            setSelectedALang('Japanese');
          }} variant="contained" className="mb-1 d-flex justify-content-between w-100 me-3">
            <LanguageToFlag value={'English'} />
            <RepeatIcon />
            <LanguageToFlag value={'Japanese'} />
          </Button>
          <Button onClick={() => {
            setSelectedQLang('English');

            setSelectedALang('Spanish');
          }} variant="contained" className="mb-1 d-flex justify-content-between w-100">
            <LanguageToFlag value={'English'} />
            <RepeatIcon />
            <LanguageToFlag value={'Spanish'} />
          </Button>
        </div>
        <div className="d-flex justify-content-between">
          <Button onClick={() => {
            setSelectedQLang('English');

            setSelectedALang('Korean');
          }} variant="contained" className="mb-1 d-flex justify-content-between w-100 me-3">
            <LanguageToFlag value={'English'} />
            <RepeatIcon />
            <LanguageToFlag value={'Korean'} />
          </Button>
          <Button onClick={() => {
            setSelectedQLang('English');

            setSelectedALang('English');
          }} variant="contained" className="mb-1 d-flex justify-content-between w-100 me-3">
            <LanguageToFlag value={'English'} />
            <RepeatIcon />
            <LanguageToFlag value={'English'} />
          </Button>
          <Button onClick={() => {
            setSelectedQLang('English');

            setSelectedALang('Mandarin');
          }} variant="contained" className="mb-1 d-flex justify-content-between w-100">
            <LanguageToFlag value={'English'} />
            <RepeatIcon />
            <LanguageToFlag value={'Mandarin'} />
          </Button>
        </div>

        {/* <div className="pt-4 pb-2">Select Languages:</div> */}
        <div className="d-flex justify-content-around pb-1 pt-3">
          <LangSelector
            header='Questions'
            selectedLang={selectedQLang}
            setSelectedLang={setSelectedQLang} />
          <div style={{ width: '24px' }}></div>
          <LangSelector
            header='Answers'
            selectedLang={selectedALang}
            setSelectedLang={setSelectedALang} />
        </div>
      </div>
      <div className="d-flex justify-content-between mb-2 p-2">
        <div className="small ps-0">({libraryData.length} categories found)</div>
        {userAlias !== '' && userDetails !== null &&
          <Button onClick={() => setShowAddGeneral(true)} variant="contained" className="mb-1 d-flex justify-content-between">
            <AddIcon />
            <span style={{ paddingTop: '2px' }}>
              Add New
            </span>
          </Button>
        }
      </div>

      {/* // language selector: */}
      {libraryData.map((cat, i) => {
        return <LibraryCategory
          key={`${i}-${cat.docId}`}
          cat={cat}
          userAlias={userAlias}
          isLast={i === (libraryData.length - 1)}
          userDetails={userDetails} />
      })}

      <div className="d-flex justify-content-end pt-2">
        <Button onClick={handleShowAbout} variant="outlined" className="mb-1 d-flex justify-content-between">About</Button>
      </div>

      <LibraryGeneralAddForm
        showAddGeneral={showAddGeneral}
        setShowAddGeneral={setShowAddGeneral}
        userDetails={userDetails}
        userAlias={userAlias}
        selectedQLang={selectedQLang}
        setSelectedQLang={setSelectedQLang}
        selectedALang={selectedALang}
        setSelectedALang={setSelectedALang}
      />


      <Modal show={showAbout} onHide={handleCloseAbout}>
        <AboutModal />
      </Modal>
      {/* <OldApp /> */}
    </Container>
  );
}

export default App;
