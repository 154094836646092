import { useState, useEffect } from 'react';
import { db } from './firebase-config';
import { collection, getDocs, query, where, orderBy, limit } from 'firebase/firestore';
import { Modal } from 'react-bootstrap';

import StudySetModal from './StudySetModal';

var fetched = false;

export default function StudySetShortcut(props) {
  const { userDetails } = props;
  const [selectedStudySet, setSelectedStudySet] = useState({});
  const [showStudySetModal, setShowStudySetModal] = useState(false);
  const handleOpenStudySet = () => setShowStudySetModal(true);
  const handleCloseStudySet = () => setShowStudySetModal(false);

  useEffect(() => {
    const studySetCollectionsRef = collection(db, "collections");

    const pathName = window.location.pathname !== '/' ? window.location.pathname.slice(1) : '';

    const getStudySets = async () => {
      // console.log('getStudySets');
      fetched = true;
      const q = query(studySetCollectionsRef, where("name", "==", pathName), orderBy("createdAt", "desc"), limit(3));

      const querySnapshot = await getDocs(q);
      const treatedData = [];
      querySnapshot.forEach((doc) => {
        treatedData.push({ docId: doc.id, ...doc.data() });
      });
      // console.log('NEW STUDY SETSasdf:', treatedData);
      if (treatedData.length >= 1) {
        // console.log('treatedData.length >= 1');

        setSelectedStudySet(treatedData[0]);
        handleOpenStudySet();
      }
    }

    if (!fetched && pathName !== '') {
      fetched = true;
      getStudySets();
    }
  }, []); // need the array here so that the accordions don't auto-close (don't understand why)

  return (

    <Modal show={showStudySetModal} onHide={handleCloseStudySet}>
      <StudySetModal studySet={selectedStudySet} setStudySet={setSelectedStudySet} userDetails={userDetails} />
    </Modal>
  );
}