import { Modal } from 'react-bootstrap';
import DownloadButtons from './DownloadButtons';

export default function AboutModal(props) {

  return (
    <>
      <Modal.Header closeButton className="bg-dark text-white">
        <Modal.Title>About Echo Prof</Modal.Title>
      </Modal.Header>

      <Modal.Body className="bg-dark text-white">
        <span className="d-inline-block pb-3">Echo Prof was built by David Dales 2019-2023</span>

        <div className="card p-2 px-5 bg-dark text-white">
          <DownloadButtons />
        </div>
      </Modal.Body>
    </>
  );
}