
import { useState, useEffect } from 'react';
import { db, auth } from './firebase-config';
import { doc, getDoc, serverTimestamp, setDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { Modal, Form } from 'react-bootstrap';

// import ChevronRight from './ChevronRight';
// import VocabHelp from './VocabHelp';
// import LanguageToFlag from './LanguageToFlag';
import VocabBuilder from './VocabBuilder';
import Flag from './Flag';

const sleep = m => new Promise(r => setTimeout(r, m))

// const studySetCollectionsRef = collection(db, "collections");

export default function AddModal(props) {
  const {
    showAddGeneral,
    setShowAddGeneral,
    userAlias,
    selectedQLang,
    selectedALang,
  } = props;

  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedName, setSelectedName] = useState('');
  const [csvVocab, setCsvVocab] = useState('');
  const [wizVocab, setWizVocab] = useState([['I eat', 'Je mange'], ['', '']]);
  const [note, setNote] = useState('');

  const [enableWiz, setEnableWiz] = useState(true);
  const handleToggleWiz = async (val) => {
    setEnableWiz(!val.target.checked);
  }

  const [showHelp, setShowHelp] = useState(false);
  const [success, setSuccess] = useState(false)
  const [invalidRow, setInvalidRow] = useState('');
  // const [enableWiz, setEnableWiz] = useState(true);

  useEffect(() => {
    setInvalidRow('');

    var lines = csvVocab.split('\n');
    lines.forEach((line, i) => {
      var fields = line.split(',');

      if (line !== '' && fields.length !== 2) {
        setInvalidRow(`Line ${i + 1} invalid: ${line}`);
      }
    });
  }, [csvVocab, wizVocab, selectedCategory, selectedName, selectedQLang, selectedALang, note]);

  const wizToCSV = (wizData) => {
    const wizDataCopy = [...wizData];
    const i = wizDataCopy.findIndex((row) => (row[0] === '' && row[1] === ''));
    if (i !== -1) wizDataCopy.splice(i, 1);

    return wizDataCopy.join('\n');
  }

  // const isStudySetTaken = async (name) => {
  //   let taken = false;
  //   const q = query(studySetCollectionsRef, where("name", "==", name), orderBy("createdAt", "desc"), limit(3));

  //   const querySnapshot = await getDocs(q);
  //   const treatedData = [];
  //   querySnapshot.forEach((doc) => {
  //     treatedData.push({ docId: doc.id, ...doc.data() });
  //   });
  //   // console.log('NEW STUDY SETSasdf:', treatedData);
  //   if (treatedData.length >= 1) {
  //     // console.log('treatedData.length >= 1');
  //     taken = true;
  //   }

  //   return taken;
  // }

  const postNew = async (e) => {

    e.preventDefault();

    if (invalidRow !== '') {
      setShowHelp(!showHelp);
      return;
    }

    if (selectedCategory === '') {
      setInvalidRow('Category has not been selected!');
      return;
    }
    if (selectedName === '') {
      setInvalidRow('Name has not been selected!');
      return;
    }

    // // convert wiz data into comma-spaced values
    const wizCSV = wizToCSV(wizVocab);

    // make sure there is wiz data if that is relevant
    if (enableWiz && (wizCSV.trim().length <= 1)) {
      setInvalidRow('Please add questions and answers before submitting study set');
      return;
    }

    const { uid } = auth.currentUser;

    // console.log(`userAlias: ${userAlias}, uid: ${uid}`);

    const newPost = {
      created_by: userAlias,
      created_by_id: uid,
      last_modified: serverTimestamp(),
      name: selectedName,
      vocab: enableWiz ? wizCSV : csvVocab,
      note: note,
    };

    // console.log(newPost);

    const newCategory = {
      'q_lang': selectedQLang,
      'a_lang': selectedALang,
      'category': selectedCategory.replace(' ', '-'),
      'created_by': userAlias,
      'created_by_id': uid,
      'last_modified': serverTimestamp(),
    }

    // check to see if name is already taken:
    // console.log('(to-do) check to see if name is taken');

    // check to see if category exists:
    // console.log('(to-do) get category...');

    const libraryRef = collection(db, "library");
    const newQ = query(libraryRef, where("category", "==", selectedCategory.replace(' ', '-')));
    const queryNewSnapshot = await getDocs(newQ);
    var existingCat = [];
    queryNewSnapshot.forEach((doc) => {
      existingCat = [
        ...existingCat, { docId: doc.id, ...doc.data(), shareables: [] }
      ];
    })

    if (existingCat.length > 0) {
      // ensure category has correct languages
      if ((existingCat[0].q_lang !== selectedQLang) || existingCat[0].a_lang !== selectedALang) {
        setInvalidRow(`Category "${selectedCategory}" already exists!`);
        return;
      }
      // update the category
      const updatedCategory = {
        'q_lang': existingCat[0].q_lang,
        'a_lang': existingCat[0].a_lang,
        'category': existingCat[0].category,
        'created_by': existingCat[0].created_by,
        'created_by_id': existingCat[0].created_by_id,
        'last_modified': serverTimestamp(),
      }
      await setDoc(
        doc(db,
          'library',
          selectedCategory.replace(' ', '-')
        ),
        updatedCategory
      );
    } else {
      // create the category
      await setDoc(
        doc(db,
          'library',
          selectedCategory.replace(' ', '-')
        ),
        newCategory
      );

    }

    // create shareable if it doesn't exist
    const postRef = doc(
      db,
      `library/${selectedCategory.replace(' ', '-')}/shareables`,
      selectedName.replace(' ', '-'),
    );

    const shareableSnapshot = await getDoc(postRef);

    if (shareableSnapshot.exists()) {
      // A document with the same name already exists
      // Handle the error, or return early, or do nothing
      setInvalidRow('Shareable with same name already exists!');
      return;
    } else {
      // Create a new document since it doesn't exist yet
      await setDoc(postRef, newPost);
    }

    // show success message
    setSuccess(true);
    // delay for 0.5s
    await sleep(500);
    // close modal
    setShowAddGeneral(false);
    // delay for 0.5s
    await sleep(500);

    setSuccess(false);
    setSelectedName('');
    setWizVocab([['', '']]);
    setCsvVocab('');
    setNote('');

    // refresh the window
    window.location.reload();
  }

  return (
    <Modal show={showAddGeneral} onHide={() => setShowAddGeneral(false)}>
      <Modal.Header closeButton className="bg-dark text-white">
        <Modal.Title>Add Study Set</Modal.Title>
      </Modal.Header>

      <Modal.Body className="bg-dark text-white">
        <Form onSubmit={postNew}>
          <div className="card bg-light bg-dark text-white border-none p-2">
            <Form.Group className="mb-1" controlId="formBasicTag">
              <Form.Label className="fw-bold px-1 bg-dark text-white">Category</Form.Label>
              <Form.Control
                className="bg-dark text-white"
                placeholder="Enter category name"
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)} />
            </Form.Group>
          </div>

          <div className="card bg-dark text-white border-none p-2 mt-4 pb-4">
            <Form.Group className="" controlId="formBasicName">
              <Form.Label className="fw-bold px-1">Name</Form.Label>
              <Form.Control
                className="bg-dark text-white"
                placeholder="Enter name for your study set"
                value={selectedName}
                onChange={(e) => {
                  e.preventDefault();

                  let regex = /[^a-z-0-9]/gi;
                  setSelectedName(e.target.value.replace(regex, ""));
                }} />
              <div className="text-secondary" style={{ position: 'absolute', right: 12, fontSize: '10px', bottom: 6 }}>
                *Only letters, numbers and hyphens allowed
              </div>
            </Form.Group>
          </div>

          <div className="card bg-light bg-dark text-white p-2 mt-4">
            <div className="d-flex justify-content-between mt-3 mx-2">
              <div style={{ background: '#333', borderRadius: '0.375rem' }} className="px-3 py-1">
                <Flag value={selectedQLang} />
                <span className="d-inline-box ps-2">{selectedQLang}</span>
              </div>
              <div style={{ background: '#333', borderRadius: '0.375rem' }} className="px-3 py-1">
                <Flag value={selectedALang} />
                <span className="d-inline-box ps-2">{selectedALang}</span>
              </div>
            </div>

            <VocabBuilder
              wizVocab={wizVocab}
              setWizVocab={setWizVocab}
              enableWiz={enableWiz}
              invalidRow={invalidRow}
              csvVocab={csvVocab}
              setCsvVocab={setCsvVocab}
              handleToggleWiz={handleToggleWiz}
            />
          </div>

          {/* note language */}
          <div className="card bg-dark text-white p-2 mt-4">
            <Form.Group className="mb-3" controlId="formBasicAlang">
              <Form.Label className="fw-bold px-1">Note:</Form.Label>
              <Form.Control
                placeholder="Enter a note (e.g. source, advice) for your study set"
                value={note}
                className="bg-dark text-white"
                onChange={(e) => setNote(e.target.value)} />
            </Form.Group>
          </div>



          {success &&
            <div className="d-flex justify-content-center mt-3 py-2 bg-success text-light rounded w-100">Study set saved!</div>
          }
          <div className="d-inline-block mt-4 align-center">
            <div className="text-danger mb-3">{invalidRow}</div>
            <button className="btn btn-primary mb-0" type="submit">Submit</button>
          </div>

          {/* <div className="mt-4 text-center">
            <div className="pb-3">
              Download the Echo Prof app - <i>an app for studying that you can talk with!</i>
            </div>
            <DownloadButtons />
          </div> */}
        </Form>
      </Modal.Body>
    </Modal>
  );
}