// import { useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Container } from 'react-bootstrap';
import DownloadButtons from './DownloadButtons';
import ComparisonTable from './ComparisonTable';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Intro(props) {
  const { showIntro, setShowIntro } = props;

  return (
    <>
      <Accordion className="mb-3"
        expanded={showIntro}
        onChange={() => setShowIntro(!showIntro)}
        sx={{ background: '#333' }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="mb-0 font-weight-lighter" style={{ fontSize: '1.25rem' }}>
            Echo Prof Intro
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="pt-2">
            <Container className="App text-center p-4 d-flex flex-column align-items-center pt-0">
              <div className="card p-2 px-5 bg-light border-white bg-dark text-white">
                <DownloadButtons />
              </div>

              <div className="pt-5">
                <p style={{ fontSize: '20px' }}>Echo Prof is speech-in/speech-out flashcard app.<br />It's great for language studies!</p>
                <p class="small mb-0 ">Echo Prof combines speech recognition and speech synthesis with performance-based spaced repetition for more effective studying.</p>
              </div>

              <div style={{ maxWidth: '420px' }} className="pt-5">
                <Carousel
                  infiniteLoop={true}
                  autoPlay={true}
                  showIndicators={false}
                  showStatus={false}
                  showThumbs={false}
                >
                  <img src="./ss1.png" style={{ maxWidth: '320px' }} className="px-3 pb-3" alt="Echo Prof - speech-in/speech-out flashcards" />
                  <img src="./ss2.png" style={{ maxWidth: '320px' }} className="px-3 pb-3" alt="Uses spaced repetition" />
                  <img src="./ss3.png" style={{ maxWidth: '320px' }} className="px-3 pb-3" alt="Great for studying languages" />
                  <img src="./ss4.png" style={{ maxWidth: '320px' }} className="px-3 pb-3" alt="Add Questions Just by Speaking" />
                  <img src="./ss5.png" style={{ maxWidth: '320px' }} className="px-3 pb-3" alt="Join the Community" />
                </Carousel>
              </div>

              <div className="p-5">
                <ComparisonTable />
              </div>
              <div className="card p-2 px-5 bg-light border-white bg-dark text-white">
                <DownloadButtons />
              </div>
            </Container >
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );

}