import { Modal, Form, Button } from 'react-bootstrap';
import { db, auth } from './firebase-config';
import { doc, serverTimestamp, setDoc } from 'firebase/firestore';
import { useState } from 'react';

export default function ChooseAliasModal(props) {
  const { handleClose, setUserAlias } = props;
  const [input, setInput] = useState('');

  const saveAlias = async (e) => {
    e.preventDefault();

    // update the alias live
    setUserAlias(input);
    const { uid } = auth.currentUser;

    // update the alias via firebase
    await setDoc(doc(db, "users", uid), {
      alias: input,
      createdAt: serverTimestamp(),
      lastActive: serverTimestamp(),
      uid
    });

    alert('alias added!');
    handleClose();
  }

  return (
    <>
      <Modal.Header closeButton className="bg-dark text-white">
        <Modal.Title>Choose Alias</Modal.Title>
      </Modal.Header>

      <Modal.Body className="bg-dark text-white">
        <Form onSubmit={saveAlias}>
          <Form.Group className="mb-3" controlId="formBasicTag">
            <Form.Label>You will be known as this on Echo Prof!</Form.Label>
            <Form.Control
              placeholder="Enter alias"
              value={input}
              onChange={(e) => setInput(e.target.value)} />
          </Form.Group>
          <Button type="submit">Submit</Button>
        </Form>
      </Modal.Body>
    </>
  );

}