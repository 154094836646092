import { useState } from 'react';
// import { Modal } from 'react-bootstrap';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import Modal from '@mui/material/Modal';

import LanguageToFlag from './LanguageToFlag';

const sleep = m => new Promise(r => setTimeout(r, m))

const availableLanguages = ['English', 'Afrikaans', 'Amharic', 'Arabic', 'Armenian', 'Azerbaijani', 'Basque', 'Bengali', 'Bulgarian', 'Burmese', 'Cantonese', 'Croatian', 'Czech', 'Danish', 'Dutch', 'Estonian', 'Filipino', 'Finnish', 'French', 'Galician', 'Georgian', 'German', 'Greek', 'Hebrew', 'Hindi', 'Hungarian', 'Icelandic', 'Indonesian', 'Italian', 'Japanese', 'Korean', 'Mandarin', 'Norwegian', 'Polish', 'Portuguese', 'Russian', 'Serbian', 'Sinhala', 'Spanish', 'Swahili', 'Swedish', 'Tamil', 'Thai', 'Turkish', 'Ukrainian', 'Urdu', 'Uzbek', 'Vietnamese', 'Zulu',];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: '80%',
  maxHeight: '900px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
};

export default function LangSelector(props) {
  const {
    header,
    selectedLang,
    setSelectedLang,
  } = props;

  const [showLangSelector, setShowLangSelector] = useState(false);
  const handleOpen = () => setShowLangSelector(true);
  const handleClose = () => setShowLangSelector(false);

  const handleSelectLang = async (lang) => {
    setSelectedLang(lang);
    await sleep(300);
    handleClose();
  }


  return (
    <div className="w-100">
      <div className="w-100 pb-1 px-3 display-flex justify-content-start">
        <div className="w-100 d-flex">{header}:</div>
      </div>
      <Button onClick={handleOpen} variant="contained" className="mb-1 d-flex justify-content-between w-100">
        <div>
          <span className=""><LanguageToFlag value={selectedLang} /></span>
          <span className="ps-2">{selectedLang}</span>
        </div>
        {/* <div> */}
        <ExpandMoreIcon />
        {/* </div> */}
      </Button>
      <Modal
        open={showLangSelector}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="bg-dark text-white">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Select {header}
          </Typography>
          <List style={{
            height: '100%',
            overflow: 'auto'
          }}>
            {availableLanguages.map((lang) => {
              return <ListItem key={lang} disablePadding>
                <ListItemButton onClick={() => handleSelectLang(lang)}>
                  <ListItemIcon>
                    <Typography>
                      <LanguageToFlag value={lang} />
                    </Typography>
                  </ListItemIcon>
                  <ListItemText primary={lang} />
                </ListItemButton>
              </ListItem>;
            })}

          </List>
        </Box>
      </Modal>
    </div>
  );
}